import loginBackground from "./authSection/login_background.png";
import splashScreenBackground from "./authSection/splash_screen_background.png";
import logo from './authSection/login_logo.png';
import studentsIcon from './dashboard/card/student.png';
import userIcon from './dashboard/card/user.png';
import quizEmptyImg from './quiz/add_question_empty_image.jpg';

import sideNavIconDashbaord from './svg/Icons/side/sidenav_dashboard.svg';
import sideNavIconCourseCatalog from './svg/Icons/side/sidenav__course_catalog.svg';
import sideNavIconWebinar from './svg/Icons/side/sidenav_webinar.svg';
import sideNavIconQuiz from './svg/Icons/side/sidenav_quiz.svg';
import sideNavIconForum from './svg/Icons/side/sidenav_forum.svg';
import sideNavIconMessage from './svg/Icons/side/sidenav_message.svg';
import sideNavIconAnnouncement from './svg/Icons/side/sidenav_announcement.svg';
import sideNavIconProfile from './svg/Icons/side/sidenav_profile.svg';
import sideNavIconUsers from './svg/Icons/side/sidenav_user.svg';
import sideNavIconVideo from './svg/Icons/side/sidenav_video.svg';
import sideNavIconReports from './svg/Icons/side/sidenav_reports.svg';
import sideNavIconStudent from './svg/Icons/side/sidenav_students.svg';
import sideNavIconPromo from './svg/Icons/sidenav_promo.svg';
import resourceIcon from "./svg/Icons/side/resource.svg"
import actresourceIcon from "./svg/Icons/side/actresource.svg"
import sideNavIconNewsletter from "./svg/Icons/side/sidenav_newsletter.svg"
import sideNavIconDocument from "./svg/Icons/side/sidenav_document.svg"
import sideNavIconNotification from "./svg/Icons/side/sidenav_notification.svg"
import sideNavIconResourses from "./svg/Icons/side/sidenav_resourses.svg"
import sideNavIconQuestions from "./svg/Icons/side/sidenav_questions.svg"

import actsideNavIconDashbaord from './svg/Icons/side/act_sidenav_dashboard.svg';
import actsideNavIconCourseCatalog from './svg/Icons/side/act_sidenav__course_catalog.svg';
import actsideNavIconWebinar from './svg/Icons/side/act_sidenav_webinar.svg';
import actsideNavIconQuiz from './svg/Icons/side/act_sidenav_quiz.svg';
import actsideNavIconForum from './svg/Icons/side/act_sidenav_forum.svg';
import actsideNavIconMessage from './svg/Icons/side/act_sidenav_message.svg';
import actsideNavIconAnnouncement from './svg/Icons/side/act_sidenav_announcement.svg';
import actsideNavIconProfile from './svg/Icons/side/act_sidenav_profile.svg';
import actsideNavIconUsers from './svg/Icons/side/act_sidenav_user.svg';
import actsideNavIconVideo from './svg/Icons/side/act_sidenav_video.svg';
import actsideNavIconReports from './svg/Icons/side/act_sidenav_reports.svg';
import actsideNavIconStudent from './svg/Icons/side/act_sidenav_students.svg';
import actsideNavIconPromo from './svg/Icons/side/act_sidenav_promo.svg';
import actsideNavIconNewsletter from './svg/Icons/side/act_sidenav_newsletter.svg';
import actsideNavIconDocument from './svg/Icons/side/act_sidenav_document.svg';
import actsideNavIconResourses from './svg/Icons/side/act_sidenav__resourses.svg'
import actsideNavIconQuestions from './svg/Icons/side/act_sidenav__questions.svg'

import successVector from './WLMS Others/success_vector.svg';
import errorVector from './WLMS Others/error_vector.svg';
import videoTumbnail from './Rectangle 1462.png';
import winspertLandscapeLogo from './WLMS_Logos/landscape_logo.svg';
import totalStudents from './WLMS Others/graduates.png';
import totalEnrollments from './WLMS Others/open-enrollment.png';
import playIcon from './svg/course-play.svg'
import student from './student.png';
import admin from './admin.png';
import announcement from './megaphone-marketing-8038812-6470693.webp';
import image404 from './404.jpg';
import close from './close.png';
import success from './success-35.png';
import welcomeText  from './Welcome.png';
import emptyReviews  from './noReview.jpg';
import noContent from './noContent.png';
import quizIcon from './quiz_icon.png';
import topBanner  from './authSection/top-banner.jpg';
import noVideo from '../Images/no-video.png'
import headerLogo from '../Images/WLMS_Logos/HeaderLOGO.png';
import pdfImage from '../Images/WLMS Others/pdfImage.png';
import notification from '../Images/notification.jpeg';
import miniIconLogo  from "../Images/MiniIconLogo.svg";


export const IMAGES = {
    loginBackground,
    splashScreenBackground,
    logo,
    userIcon,
    studentsIcon,
    quizEmptyImg,
    headerLogo,


    sideNavIconDashbaord,
    sideNavIconCourseCatalog,
    sideNavIconWebinar,
    sideNavIconQuiz,
    sideNavIconForum,
    sideNavIconMessage,
    sideNavIconAnnouncement,
    sideNavIconProfile,
    sideNavIconUsers,
    sideNavIconVideo,
    sideNavIconReports,
    sideNavIconStudent,
    sideNavIconPromo,
    resourceIcon,
    sideNavIconNewsletter,
    sideNavIconDocument,
    sideNavIconNotification,
    sideNavIconResourses,
    sideNavIconQuestions,


    actsideNavIconDashbaord,
    actsideNavIconCourseCatalog,
    actsideNavIconWebinar,
    actsideNavIconQuiz,
    actsideNavIconForum,
    actsideNavIconMessage,
    actsideNavIconAnnouncement,
    actsideNavIconProfile,
    actsideNavIconUsers,
    actsideNavIconVideo,
    actsideNavIconReports,
    actsideNavIconStudent,
    actsideNavIconPromo,
    actresourceIcon,
    actsideNavIconNewsletter,
    actsideNavIconDocument,
    actsideNavIconResourses,
    actsideNavIconQuestions,

    errorVector,
    successVector,
    videoTumbnail,
    winspertLandscapeLogo,
    totalStudents,
    totalEnrollments,
    playIcon,
    admin,
    student,
    announcement,
    image404,
    close,
    success,
    welcomeText,
    emptyReviews,
    noContent,
    quizIcon,
    topBanner,
    noVideo,
    pdfImage,
    notification,
    miniIconLogo,

}
