import React, { useEffect, useState, useRef } from "react";
import {
  IconButtonComponent,
  SwitchButtonComponet,
  TextIconButtonComponent,
  PaginationTable,
} from "../../../component/atom";
import { Box, Grid } from "@mui/material";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import DialogComponent from "../../../component/atom/Dialog/Dialog";
import { PopUpMessageComponent } from "../../../component/molecule";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import {
  AddUserForm,
  UpdateUserForm,
  ViewUserForm,
} from "../../../component/molecule/Forms";
import { useNavigate } from "react-router-dom";

const UsersScreen = ({
  getUserDetails,
  userList,
  userStatus,
  updateStatus,
  deleteAdminUser,
  deleteUser,
  verifyToken,
}) => {
  const [addUser, setAddUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const initialFetchDone = useRef(false);
  const navigate = useNavigate();

  // Debounce function to limit API calls
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Fetch users with pagination and search
  const fetchUsers = (page, perPage, search = "") => {
    console.log("Fetching users with:", { page, perPage, search });
    getUserDetails({
      type: 1,
      page,
      per_page: perPage,
      search: search.trim(),
    });
  };

  // Debounced version of fetchUsers
  const debouncedFetchUsers = useRef(debounce(fetchUsers, 300)).current;

  // Initial fetch on mount
  useEffect(() => {
    if (!initialFetchDone.current) {
      fetchUsers(currentPage, rowsPerPage, searchQuery);
      initialFetchDone.current = true;
    }
  }, []);

  // Fetch on state changes with debounce
  useEffect(() => {
    if (initialFetchDone.current) {
      debouncedFetchUsers(currentPage, rowsPerPage, searchQuery);
    }
  }, [currentPage, rowsPerPage, searchQuery]);

  // Sync users and pagination data with userList
  useEffect(() => {
    if (userList) {
      console.log("Received userList:", userList);
      const data = userList.data || userList; // Adjust based on structure
      console.log("Setting users to:", data);
      setUsers(data || []);
      if (userList.last_page) {
        console.log("Setting totalPages to:", userList.last_page);
        setTotalPages(userList.last_page);
      } else {
        setTotalPages(Math.ceil(data.length / rowsPerPage) || 1);
      }
      if (userList.per_page && userList.per_page !== rowsPerPage) {
        console.log("Setting rowsPerPage to:", userList.per_page);
        setRowsPerPage(userList.per_page);
      }
    }
  }, [userList]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: { filter: true, sort: false, display: true },
    },
    {
      name: "first_name",
      label: "First Name",
      options: { filter: true, sort: false },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: { filter: true, sort: false },
    },
    {
      name: "email",
      label: "Email",
      options: { filter: true, sort: false },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <SwitchButtonComponet
            checked={value ? true : false}
            onChange={() => updateUserStatus(tableMeta, value)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
      },
    },
    {
      name: "view",
      label: "View",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <IconButtonComponent
            btnType={"viewIconbtn"}
            onclick={() => viewBtn(tableMeta)}
          />
        ),
      },
    },
    {
      name: "edit",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <IconButtonComponent
            btnType={"editbtn"}
            onclick={() => editBtn(value, tableMeta)}
          />
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta) => (
          <IconButtonComponent
            btnType={"deleteIconbtn"}
            onclick={() => deleteUserSelect(tableMeta)}
          />
        ),
      },
    },
  ];

  const updateUserStatus = (tableMeta, value) => {
    const updatedUsers = users?.map((item) => {
      if (item?.id == tableMeta?.rowData[0]) {
        const currentStatus = item?.is_active;
        const updatedStatus = currentStatus == 1 ? 0 : 1;
        updateStatus({ user_id: tableMeta?.rowData[0], status: updatedStatus });
        return { ...item, is_active: updatedStatus }; // Update local state correctly
      }
      return item;
    });
    setUsers(updatedUsers);
  };

  const viewBtn = (tableMeta) => {
    const user = users?.find((item) => item?.id == tableMeta.rowData[0]);
    if (user) {
      setUserData(user);
      setViewUser(true);
    }
  };

  const editBtn = (value, tableMeta) => {
    const user = users?.find((item) => item?.id == tableMeta.rowData[0]);
    if (user) {
      setUserData(user);
      setEditUser(true);
    }
  };

  const deleteUserSelect = (tableMeta) => {
    const user = users?.find((item) => item?.id == tableMeta?.rowData[0]);
    if (user) {
      setUserId(user?.id);
      setDeleteUserPopup(true);
    }
  };

  const deletebtnPress = () => {
    deleteAdminUser(userId);
    setDeleteUserPopup(false);
  };

  return (
    <>
      <Box className="main-screen-container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Grid item>
            <HeadingComponent
              text={"Users"}
              fontweigth={600}
              size={40}
              fontfamily={"Montserrat"}
            />
          </Grid>
          <Grid item>
            <TextIconButtonComponent
              btnText={"Add User"}
              icon={faUserPlus}
              onclick={() => setAddUser(true)}
            />
          </Grid>
        </Grid>
        <Box className="common-admin-content-wrap">
          <PaginationTable
            columns={columns}
            data={users}
            totalRecords={totalPages}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            onRowsPerPageChange={(perPage) => setRowsPerPage(perPage)}
            onSearchChange={(searchText) => setSearchQuery(searchText)}
          />
        </Box>
      </Box>

      <PopUpMessageComponent
        open={deleteUserPopup}
        type={"other"}
        title={"Delete!"}
        message={"Are you sure you want delete?"}
        btntext={"Yes, delete"}
        onclick={() => deletebtnPress()}
        altbtntext={"No"}
        altonclick={() => setDeleteUserPopup(false)}
        onclose={() => setDeleteUserPopup(false)}
      />
      <DialogComponent
        title={"Add User"}
        children={
          <AddUserForm type={"add"} onclickcancel={() => setAddUser(false)} />
        }
        open={addUser}
        onClose={() => setAddUser(false)}
      />
      <DialogComponent
        title={"View User"}
        children={
          <ViewUserForm data={userData} onClose={() => setViewUser(false)} />
        }
        open={viewUser}
        onClose={() => setViewUser(false)}
      />
      <DialogComponent
        title={"Edit User"}
        children={
          <UpdateUserForm
            user={userData}
            onclickcancel={() => setEditUser(false)}
          />
        }
        open={editUser}
        onClose={() => setEditUser(false)}
      />
    </>
  );
};

export default connect(
  (state) => ({
    userList: state.users.get("userList"),
    userStatus: state.users.get("userStatus"),
    deleteUser: state.users.get("deleteUser"),
  }),
  {
    getUserDetails: Actions.users.getAdminUserInfo,
    updateStatus: Actions.users.updateUserStatus,
    deleteAdminUser: Actions.users.deleteAdminUser,
    verifyToken: Actions.auth.verifyToken,
  }
)(UsersScreen);