import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { IconButtonComponent } from "../../atom";
import _ from "lodash";

export default ({
  item,
  index,
  onclick = () => {},
  onPressDelete = () => {},
  onPressEdit = () => {},
  isChecked = false,
  onCheckboxChange = () => {},
  checked = false,
  isView = false,
  isEdit = false,
  isDelete = false,
  sx, // Accept sx prop from ExpandableViewQuestion
}) => {
  return (
    <Box
      className="admin-question-wrap"
      sx={{
        display: "flex",
        justifyContent: "space-between", // Space between question and buttons
        alignItems: "center", // Vertically center content
        width: "100%",
        maxWidth: "100%", // Prevent overflow
        padding: "10px", // Reduced padding for shorter height
        borderBottom: "1px solid #eee",
        minHeight: "40px", // Compact height
        ...sx, // Apply styles passed from parent
      }}
    >
      <Box
        className="admin-question"
        sx={{
          maxWidth: "70%", // Limit question width to avoid overlap with buttons
          paddingInlineStart: 1, // Reduced padding
        }}
      >
        <div
          style={{
            whiteSpace: "normal", // Allow text to wrap
            wordBreak: "break-word", // Break long words
            overflowWrap: "break-word", // Ensure wrapping
            maxWidth: "100%", // Constrain within parent
            lineHeight: "1.2", // Tighter line height
            margin: 0, // Remove default margins
          }}
          dangerouslySetInnerHTML={{
            __html: `${index + 1 < 10 ? `0${index + 1}` : index + 1}. ${_.get(
              item,
              "question",
              ""
            )}`,
          }}
        />
      </Box>
      <Box
        className="admin-question-btn-section"
        sx={{
          display: "flex",
          alignItems: "center",
          flexShrink: 0, // Prevent buttons from shrinking
          gap: "10px", // Tight spacing between buttons
          right: 5,
        }}
      >
        {isView && (
          <IconButtonComponent
            btntitle={"View"}
            btnType={"viewIconbtn"}
            onclick={onclick}
            sx={{ padding: "4px" }} // Smaller button size
          />
        )}
        {isEdit && (
          <IconButtonComponent
            btnType={"editbtn"}
            onclick={onPressEdit}
            sx={{ padding: "4px" }} // Smaller button size
          />
        )}
        {isDelete && (
          <IconButtonComponent
            btntitle={"Delete"}
            btnType={"deleteIconbtn"}
            onclick={onPressDelete}
            sx={{ padding: "4px" }} // Smaller button size
          />
        )}
        {checked && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ padding: "4px" }} // Reduced padding
                checked={isChecked}
                onChange={(e) => onCheckboxChange(e.target.checked, item)}
              />
            }
          />
        )}
      </Box>
    </Box>
  );
};