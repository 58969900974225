import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";

const PaginationTable = ({
  title,
  columns,
  data,
  totalItems, // Renamed from totalRecords to reflect total records
  currentPage,
  rowsPerPage = 10,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  onSearchChange = () => {},
  filter = true,
  download = true,
  selectableRowsHideCheckboxes = true,
  expandableRows = false,
  onRowsSelect = () => {},
}) => {
  const options = {
    filterType: "dropdown",
    download: download,
    filter: filter,
    print: false,
    viewColumns: false,
    search: true,
    selectableRowsHideCheckboxes: selectableRowsHideCheckboxes,
    selectableRows: "multiple",
    rowsPerPage: rowsPerPage,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    count: totalItems, // Use total records (e.g., 3291)
    page: currentPage - 1, // 1-based to 0-based
    onChangePage: (newPage) => {
      console.log("MUIDataTable onChangePage:", newPage); // Debug
      onPageChange(newPage + 1); // 0-based to 1-based
    },
    onChangeRowsPerPage: (newRowsPerPage) => {
      console.log("MUIDataTable onChangeRowsPerPage:", newRowsPerPage); // Debug
      onRowsPerPageChange(newRowsPerPage);
    },
    expandableRows: expandableRows,
    onRowSelectionChange: onRowsSelect,
    onSearchChange: (searchText) => {
      const sanitizedSearch = searchText ? searchText.trim() : "";
      console.log("MUIDataTable onSearchChange:", sanitizedSearch); // Debug
      onSearchChange(sanitizedSearch);
    },
  };

  return (
    <MUIDataTable
      key={currentPage}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export default PaginationTable;