import React, { useEffect, useState, useRef } from "react";
import {
  PaginationTable,
  SwitchButtonComponet,
  TextIconButtonComponent,
} from "../../../component/atom";
import { Box, Grid, LinearProgress, Stack } from "@mui/material";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import DropDownComponent from "../../../component/atom/Inputs/DropDown";
import DialogComponent from "../../../component/atom/Dialog/Dialog";
import AddStudentForm from "../../../component/molecule/Forms/AddStudentForm";
import { faUserPlus, faLaptop } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import TextButtonComponet from "../../../component/atom/Buttons/TextButton";
import AddReviewForm from "../../../component/molecule/Forms/courseAdmin/AdminAddReviewForm";
import { ResetDeviceIdForm } from "../../../component/molecule/Forms";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const StudentsScreen = ({
  getAdminUserDetails,
  adminStudents,
  courseList,
  getCourseList,
  studentStatus,
  updateStudentStatus,
  coursecatalog,
  getCousreCatalog,
}) => {
  const [addStudent, setAddStudent] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [resetDeviceId, setResetDeviceId] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [courseId, setCourseId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [studentPaymentData, setStudentPaymentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0); // New state for total records
  const [searchQuery, setSearchQuery] = useState("");

  const initialFetchDone = useRef(false);
  const navigate = useNavigate();

  const onNavigate = (path, obj = {}) => {
    if (path) {
      navigate(path, obj);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const fetchStudents = (page, perPage, course, search = "") => {
    console.log("Fetching students for page:", page);
    getAdminUserDetails({
      course: course === 0 ? "" : course,
      type: 2,
      page,
      per_page: perPage,
      search: search.trim(),
    });
  };

  const debouncedFetchStudents = useRef(debounce(fetchStudents, 300)).current;

  useEffect(() => {
    if (!initialFetchDone.current) {
      fetchStudents(currentPage, rowsPerPage, courseId, searchQuery);
      initialFetchDone.current = true;
    }
  }, []);

  useEffect(() => {
    if (initialFetchDone.current) {
      debouncedFetchStudents(currentPage, rowsPerPage, courseId, searchQuery);
    }
  }, [currentPage, rowsPerPage, courseId, searchQuery]);

  useEffect(() => {
    if (adminStudents) {
      console.log("adminStudents received:", adminStudents);
      const data = adminStudents.data || adminStudents;
      setStudentList(data);

      // Set total items (total records)
      if (adminStudents.total) {
        setTotalItems(adminStudents.total); // 3291
      }

      // Set total pages for internal tracking
      if (adminStudents.total && adminStudents.per_page) {
        const calculatedTotalPages = Math.ceil(adminStudents.total / adminStudents.per_page);
        console.log("Calculated total pages:", calculatedTotalPages);
        setTotalPages(calculatedTotalPages); // 330
      } else if (adminStudents.last_page) {
        console.log("Using last_page:", adminStudents.last_page);
        setTotalPages(adminStudents.last_page); // 330
      }

      if (adminStudents.per_page && adminStudents.per_page !== rowsPerPage) {
        setRowsPerPage(adminStudents.per_page);
      }
    }
  }, [adminStudents]);

  useEffect(() => {
    getCourseList();
    getCousreCatalog();
  }, []);

  const onNavigateUser = (tableMeta, value) => {
    for (const item of studentList) {
      if (item?.id == tableMeta?.rowData[0]) {
        onNavigate("/admin-course-student-progress", {
          state: { studentId: _.get(item, "id", ""), student: item },
        });
      }
    }
  };

  const columns = [
    { name: "id", label: "ID", options: { filter: true, sort: false } },
    { name: "course_id", label: "", options: { filter: true, sort: false, display: false } },
    { name: "first_name", label: "First Name", options: { filter: true, sort: false } },
    { name: "last_name", label: "Last Name", options: { filter: true, sort: false } },
    { name: "email", label: "Email", options: { filter: true, sort: false } },
    {
      name: "payment_details",
      label: "Enrolled Course",
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value) =>
          value?.map((item) => (
            <ul key={item?.course?.id}>
              <li>{item?.course?.name}</li>
            </ul>
          )),
      },
    },
    {
      name: "course_progress",
      label: "Progress",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const progressData = Array.isArray(value) ? value : [value];
          return (
            <Stack spacing={1}>
              {progressData.map((progress, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress?.progress || 0}
                    sx={{ width: "100%", marginRight: 1 }}
                  />
                  <span>{`${Math.round(progress?.progress || 0)}%`}</span>
                </Box>
              ))}
            </Stack>
          );
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <SwitchButtonComponet
            checked={value ? true : false}
            onChange={() => updateAdminStatus(tableMeta, value)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
      },
    },
    {
      name: "id",
      label: "Add Review",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Box sx={{ minWidth: 150 }}>
            <TextButtonComponet
              text={"Add Review"}
              onButtonClick={() => addReviewBtn(value, tableMeta)}
            />
          </Box>
        ),
      },
    },
    {
      name: "id",
      label: "Progress",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Box sx={{ minWidth: 100 }}>
            <TextButtonComponet
              text={"View Progress"}
              onButtonClick={() => onNavigateUser(tableMeta, value)}
            />
          </Box>
        ),
      },
    },
  ];

  const updateAdminStatus = (tableMeta) => {
    const updatedStudents = studentList?.map((item) => {
      if (item?.id == tableMeta?.rowData[0]) {
        const currentStatus = item?.is_active;
        const updatedStatus = currentStatus == 1 ? 0 : 1;
        updateStudentStatus({
          user_id: tableMeta?.rowData[0],
          status: updatedStatus,
        });
        return { ...item, is_active: updatedStatus };
      }
      return item;
    });
    setStudentList(updatedStudents);
  };

  const filterStudentsDetails = (courseId) => {
    setCourseId(courseId);
    setCurrentPage(1);
    debouncedFetchStudents(1, rowsPerPage, courseId, searchQuery);
  };

  const addReviewBtn = (value, tableMeta) => {
    setStudentId(value);
    setCourseId(tableMeta?.rowData[1]);
    setOpenReview(true);
    setStudentPaymentData(tableMeta?.rowData[5]);
  };

  return (
    <>
      <Box className="main-screen-container">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <HeadingComponent
              text={"Students"}
              fontweigth={600}
              size={40}
              fontfamily={"Montserrat"}
            />
          </Grid>
          <Grid item className="student-search-btn-section">
            <Box style={{ marginRight: 10 }}>
              <TextIconButtonComponent
                buttonStyleClass="btn btn-primary"
                icon={faLaptop}
                btnText={"Reset device id"}
                onclick={() => setResetDeviceId(true)}
              />
            </Box>
            <TextIconButtonComponent
              buttonStyleClass="btn btn-primary"
              icon={faUserPlus}
              btnText={"Add Student"}
              onclick={() => setAddStudent(true)}
            />
            <Box className="student-search-btn-inner-section">
              <DropDownComponent
                list={courseList}
                initialValue={"All Courses"}
                selectedValue={courseId}
                onchange={(e) => filterStudentsDetails(e.target.value)}
                placeholder={"All Courses"}
              />
            </Box>
          </Grid>
        </Grid>

        <Box className="common-admin-content-wrap">
          <PaginationTable
            columns={columns}
            data={studentList}
            totalItems={totalItems} // Pass total records (3291)
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            onPageChange={(page) => {
              console.log("Page change requested to:", page);
              setCurrentPage(page);
            }}
            onRowsPerPageChange={(perPage) => {
              console.log("Rows per page changed to:", perPage);
              setRowsPerPage(perPage);
              setCurrentPage(1);
            }}
            onSearchChange={(searchText) => {
              console.log("Search query changed to:", searchText);
              setSearchQuery(searchText);
            }}
          />
        </Box>
      </Box>

      <DialogComponent
        title={"Reset Device Id"}
        children={<ResetDeviceIdForm onclickcancel={() => setResetDeviceId(false)} />}
        onClose={() => setResetDeviceId(false)}
        open={resetDeviceId}
      />

      <DialogComponent
        title={"Add Student"}
        children={
          <AddStudentForm
            dropdownList={courseList}
            onclickcancel={() => setAddStudent(false)}
          />
        }
        onClose={() => setAddStudent(false)}
        open={addStudent}
      />
      <DialogComponent
        title={"Add Review"}
        children={
          <AddReviewForm
            courseId={courseId}
            courses={studentPaymentData}
            userId={studentId}
            dropdownList={courseList}
            onclickcancel={() => setOpenReview(false)}
          />
        }
        onClose={() => setOpenReview(false)}
        open={openReview}
      />
    </>
  );
};

export default connect(
  (state) => ({
    adminStudents: state.students.get("adminStudents"),
    courseList: state.students.get("commonCourseList"),
    studentStatus: state.students.get("studentStatusUpdate"),
    coursecatalog: state.course.get("coursecatalog"),
  }),
  {
    getAdminUserDetails: Actions.students.getAdminUserDetails,
    getCourseList: Actions.students.getCourseList,
    updateStudentStatus: Actions.students.updateStudentStatus,
    getCousreCatalog: Actions.course.getCousreCatalog,
  }
)(StudentsScreen);